$theme-colors: (
  "primary": #105689,
  "secondary": #6c757d,
  "info": #484954,
  "light": lighten(#4f6471, 35%),
  "dark": #323437,
  "danger": #721c24,
  "warning": #ffc107,
  "success": #155724,
  "white": white,
);

@import "bootstrap";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
#root {
  min-height: 100vh;
  background-color: map-get($theme-colors, "light");
}

// * {
//   border-radius: 0 !important;
// }

h1 {
  margin-bottom: 30px;
}

i.fa {
  margin-right: 5px;
}

.sidebar {
  background: map-get($theme-colors, "info");
}

.resources-table-actions {
  .search-filled {
    border-right: none !important;
  }

  .search-clear {
    border-left: none !important;
    border: var(--bs-border-width) solid var(--bs-border-color);
    color: map-get($theme-colors, "dark");
  }
}

.resources-table {
  // min-height: 460px;
  .table-cell {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    // max-width: 200px;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    // background: rgba(#d1e3de, 0.2);
    background: rgba(black, 0.025);
  }

  .table-striped tbody tr:hover {
    background: rgba(map-get($theme-colors, "info"), 0.4);
    background: rgba($primary, 0.1);
    background: rgba(black, 0.1);
  }

  td,
  th {
    padding: 0.5rem 0.25rem !important;
  }
}

.btn:focus {
  outline: none !important;
}

.btn:focus {
  box-shadow: none;
}

.form-check {
  input[type="checkbox"] {
    cursor: pointer;
  }

  > label {
    cursor: pointer;
    user-select: none;
  }
}

.auth-login-container {
  max-width: 450px;
  form {
    .form-name,
    .form-password {
      label {
        @extend .form-label;
      }
      input {
        @extend .form-control;
      }
    }
  }
}

.react-ios-time-picker-main {
  @extend .flex-grow-1;
}
