// @import "bootstrap";

#tracking {
  .react-ios-time-picker-input {
    width: 100%;
    cursor: pointer;
    text-align: center;
  }

  .activity textarea:not(.active) {
    background-color: var(--bs-secondary-bg);
  }

  #activity-settings {
    position: absolute;
    right: 0px;
    top: -7px;
    width: 45px;
    height: 45px;

    &:after {
      display: none !important;
    }
  }
}

.spinner_Wezc {
  transform-origin: center;
  animation: spinner_Oiah 0.75s step-end infinite;
}
@keyframes spinner_Oiah {
  8.3% {
    transform: rotate(30deg);
  }
  16.6% {
    transform: rotate(60deg);
  }
  25% {
    transform: rotate(90deg);
  }
  33.3% {
    transform: rotate(120deg);
  }
  41.6% {
    transform: rotate(150deg);
  }
  50% {
    transform: rotate(180deg);
  }
  58.3% {
    transform: rotate(210deg);
  }
  66.6% {
    transform: rotate(240deg);
  }
  75% {
    transform: rotate(270deg);
  }
  83.3% {
    transform: rotate(300deg);
  }
  91.6% {
    transform: rotate(330deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
